<template>
    <div>
        <div class="bg-white text-purple d-flex justify-content-between align-items-center p-3 rounded">
            <div class="fs-4">Atendimentos</div>
            <div class="d-flex align-items-center">
                <div class="text-nowrap">Total de Atendimentos</div>
                <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ totalAttendances }}</div>
                <b-button class="text-white" variant="green" v-b-modal.modalFilter>Filtrar</b-button>
            </div>
        </div>
        <div>
            <b-row class="m-0 mt-3">
                <b-col class="p-0">
                    <div class="bg-warning shadow-sm text-dark d-flex justify-content-between align-items-center rounded p-3">
                        <div class="fs-5">
                            Auto Atendimento
                        </div>
                        <div class="fs-5 bg-white text-dark px-2 rounded-pill">
                            {{ openedCount }}
                        </div>
                    </div>
                    <div v-if="openedCount">
                        <div class="shadow-sm rounded mt-3" v-for="item in attendances.filter(el => el.status == 'opened')" :key="item.id">
                            <div class="bg-warning rounded-top d-flex flex-column p-3">
                                <div class="d-flex justify-content-end">
                                    <b-icon :id="`tooltipForward${item._id}`" class="bg-white rounded-circle p-1" flip-h font-scale="1.75" icon="people-fill" role="button" v-b-modal.modalForward @click="selectUser(item); getForwardList()"></b-icon>
                                    <b-tooltip :target="`tooltipForward${item._id}`" triggers="hover" custom-class="top-0" boundary="document" placement="left">
                                        Encaminhar
                                    </b-tooltip>
                                </div>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                    <b-avatar :src="item.photoURL" class="bg-secondary text-white" size="5.5em"></b-avatar>
                                    <div class="text-dark fs-5">
                                        {{ item.clientName }}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white rounded-bottom">
                                <div class="px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="upc" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Protocolo:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.protocol || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="phone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Contato:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.clientNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="chat-left-text" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Status:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.status | translateStatus }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="calendar" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Data de Início:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.createdAt | date('DD/MM/YY HH:mm:ss') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="telephone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Canal de envio:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.channelNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="people" flip-h font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Departamento:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.departmentName || getDepartmentName(item.departmentId) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="person" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Operador:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.operatorName || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                            <b-icon icon="clock" font-scale="2.1"></b-icon>
                                        </div>
                                        <div class="ms-1 d-flex flex-column justify-content-center">
                                            <div class="text-secondary">
                                                {{ item.timer || "- - - -" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <b-button class="text-white py-1 px-2 small-text" variant="green" v-b-modal.modalAttendance @click="getAttendanceMessages(item)">Ver</b-button>
                                        <b-button class="text-white py-1 px-2 ms-2 small-text" variant="danger" @click="selectUser(item); finishAttendance(false)">Finalizar Atendimento</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-warning text-dark p-3 rounded shadow-sm mt-3" v-else>
                        Nenhum Atendimento Encontrado!
                    </div>
                </b-col>
                <b-col class="p-0 ps-3">
                    <div class="bg-purple shadow-sm text-light d-flex justify-content-between align-items-center rounded p-3">
                        <div class="fs-5">
                            Fila de Espera
                        </div>
                        <div class="fs-5 bg-white text-dark px-2 rounded-pill">
                            {{ queueCount }}
                        </div>
                    </div>
                    <div v-if="queueCount">
                        <div class="shadow-sm rounded mt-3" v-for="item in attendances.filter(el => el.status == 'offline_operators')" :key="item.id">
                            <div class="bg-purple rounded-top d-flex flex-column p-3">
                                <div class="d-flex justify-content-end">
                                    <b-icon :id="`tooltipForward${item._id}`" class="bg-white rounded-circle p-1" flip-h font-scale="1.75" icon="people-fill" role="button" v-b-modal.modalForward @click="selectUser(item); getForwardList()"></b-icon>
                                    <b-tooltip :target="`tooltipForward${item._id}`" triggers="hover" custom-class="me-2 top-0" boundary="document" placement="left">
                                        Encaminhar
                                    </b-tooltip>
                                </div>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                    <b-avatar :src="item.photoURL" class="bg-secondary text-white" size="5.5em"></b-avatar>
                                    <div class="text-white fs-5">
                                        {{ item.clientName }}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white rounded-bottom">
                                <div class="px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="upc" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Protocolo:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.protocol || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="phone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Contato:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.clientNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="chat-left-text" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Status:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.status | translateStatus }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="calendar" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Data de Início:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.createdAt | date('DD/MM/YY HH:mm:ss') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="telephone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Canal de envio:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.channelNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="people" flip-h font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Departamento:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.departmentName || getDepartmentName(item.departmentId) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="person" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Operador:
                                        </div>
                                        <div class="text-secondary">
                                            {{ (item.operatorName != 'Bot' ? item.operatorName : getOperatorName(item.operatorId)) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                            <b-icon icon="clock" font-scale="2.1"></b-icon>
                                        </div>
                                        <div class="ms-1 d-flex flex-column justify-content-center">
                                            <div class="text-secondary">
                                                {{ item.timer || "- - - -" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <b-button class="text-white py-1 px-2 small-text" variant="green" v-b-modal.modalAttendance @click="getAttendanceMessages(item)">Ver</b-button>
                                        <b-button class="text-white py-1 px-2 ms-2 small-text" variant="danger" @click="selectUser(item); finishAttendance(false)">Finalizar Atendimento</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-purple text-light p-3 rounded shadow-sm mt-3" v-else>
                        Nenhum Atendimento Encontrado!
                    </div>
                </b-col>
                <b-col class="p-0 ps-3">
                    <div class="bg-primary shadow-sm text-light d-flex justify-content-between align-items-center rounded p-3">
                        <div class="fs-5">
                            Aguardando Atendimento
                        </div>
                        <div class="fs-5 bg-white text-dark px-2 rounded-pill">
                            {{ waitingCount }}
                        </div>
                    </div>
                    <div v-if="waitingCount">
                        <div class="shadow-sm rounded mt-3" v-for="item in attendances.filter(el => el.status == 'waiting')" :key="item.id">
                            <div class="bg-primary rounded-top d-flex flex-column p-3">
                                <div class="d-flex justify-content-end">
                                    <b-icon :id="`tooltipForward${item._id}`" class="bg-white rounded-circle p-1" flip-h font-scale="1.75" icon="people-fill" role="button" v-b-modal.modalForward @click="selectUser(item); getForwardList()"></b-icon>
                                    <b-tooltip :target="`tooltipForward${item._id}`" triggers="hover" custom-class="me-2 top-0" boundary="document" placement="left">
                                        Encaminhar
                                    </b-tooltip>
                                </div>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                    <b-avatar :src="item.photoURL" class="bg-secondary text-white" size="5.5em"></b-avatar>
                                    <div class="text-white fs-5">
                                        {{ item.clientName }}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white rounded-bottom">
                                <div class="px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="upc" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Protocolo:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.protocol || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="phone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Contato:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.clientNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="chat-left-text" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Status:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.status | translateStatus }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="calendar" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Data de Início:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.createdAt | date('DD/MM/YY HH:mm:ss') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="telephone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Canal de envio:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.channelNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="people" flip-h font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Departamento:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.departmentName || getDepartmentName(item.departmentId) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="person" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Operador:
                                        </div>
                                        <div class="text-secondary">
                                            {{ (item.operatorName != 'Bot' ? item.operatorName : getOperatorName(item.operatorId)) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                            <b-icon icon="clock" font-scale="2.1"></b-icon>
                                        </div>
                                        <div class="ms-1 d-flex flex-column justify-content-center">
                                            <div class="text-secondary">
                                                {{ item.timer || "- - - -" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <b-button class="text-white py-1 px-2 small-text" variant="green" v-b-modal.modalAttendance @click="getAttendanceMessages(item)">Ver</b-button>
                                        <b-button class="text-white py-1 px-2 ms-2 small-text" variant="danger" @click="selectUser(item); finishAttendance(false)">Finalizar Atendimento</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-primary text-light p-3 rounded shadow-sm mt-3" v-else>
                        Nenhum Atendimento Encontrado!
                    </div>
                </b-col>
                <b-col class="p-0 ps-3">
                    <div class="bg-green shadow-sm text-light d-flex justify-content-between align-items-center rounded p-3">
                        <div class="fs-5">
                            Em Atendimento
                        </div>
                        <div class="fs-5 bg-white text-dark px-2 rounded-pill">
                            {{ inAttendanceCount }}
                        </div>
                    </div>
                    <div v-if="inAttendanceCount">
                        <div class="shadow-sm rounded mt-3" v-for="item in attendances.filter(el => el.status == 'in_attendance')" :key="item.id">
                            <div class="bg-green rounded-top d-flex flex-column p-3">
                                <div class="d-flex justify-content-end">
                                    <b-icon :id="`tooltipForward${item._id}`" class="bg-white rounded-circle p-1" flip-h font-scale="1.75" icon="people-fill" role="button" v-b-modal.modalForward @click="selectUser(item); getForwardList()"></b-icon>
                                    <b-tooltip :target="`tooltipForward${item._id}`" triggers="hover" custom-class="me-2 top-0" boundary="document" placement="left">
                                        Encaminhar
                                    </b-tooltip>
                                </div>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                    <b-avatar :src="item.photoURL" class="bg-secondary text-white" size="5.5em"></b-avatar>
                                    <div class="text-light fs-5">
                                        {{ item.clientName }}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white rounded-bottom">
                                <div class="px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="upc" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Protocolo:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.protocol || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="phone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Contato:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.clientNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="chat-left-text" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Status:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.status | translateStatus }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="calendar" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Data de Início:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.createdAt | date('DD/MM/YY HH:mm:ss') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="telephone" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Canal de envio:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.channelNumber || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="people" flip-h font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Departamento:
                                        </div>
                                        <div class="text-secondary">
                                            {{ item.departmentName || getDepartmentName(item.departmentId) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <b-icon icon="person" font-scale="2.1"></b-icon>
                                    </div>
                                    <div class="ms-1 d-flex flex-column justify-content-center">
                                        <div class="fw-bold">
                                            Operador:
                                        </div>
                                        <div class="text-secondary">
                                            {{ (item.operatorName != 'Bot' ? item.operatorName : getOperatorName(item.operatorId)) || "- - - -" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="border-top px-3 py-2 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                            <b-icon icon="clock" font-scale="2.1"></b-icon>
                                        </div>
                                        <div class="ms-1 d-flex flex-column justify-content-center">
                                            <div class="text-secondary">
                                                {{ item.timer || "- - - -" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <b-button class="text-white py-1 px-2 small-text" variant="green" v-b-modal.modalAttendance @click="getAttendanceMessages(item)">Ver</b-button>
                                        <b-button class="text-white py-1 px-2 ms-2 small-text" variant="danger" @click="selectUser(item); finishAttendance(false)">Finalizar Atendimento</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-green text-light p-3 rounded shadow-sm mt-3" v-else>
                        Nenhum Atendimento Encontrado!
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-modal id="modalFilter" ref="modalFilter" hide-footer title="Filtrar Atendimentos" header-class="py-0">
            <b-form @submit.prevent="getAttendances(1, true)">
                <b-form-group label="Selecione o Filtro:" label-class="text-purple fw-semibold">
                    <b-form-select class="w-100 rounded p-2 border-grey" v-model="filters.type">
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option value="keyword">Palavra-Chave</b-form-select-option>
                        <b-form-select-option value="department">Departamento</b-form-select-option>
                        <b-form-select-option value="operator">Operador</b-form-select-option>
                    </b-form-select>
                    <b-form-input class="mt-3 border-grey" placeholder="Buscar por nome, contato ou protocolo" v-model="filters.keyword" v-if="filters.type == 'keyword'"></b-form-input>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filters.department" v-else-if="filters.type == 'department'">
                        <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                    </b-form-select>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filters.operator" v-else-if="filters.type == 'operator'">
                        <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <div class="mt-3">
                    <b-button type="submit" class="text-white" variant="green" @click="getAttendances(1, true); $bvModal.hide('modalFilter')">Pesquisar</b-button>
                    <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalFilter')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modalForward" ref="modalForward" hide-footer title="Encaminhar Atendimento" header-class="py-0" v-if="clientSelected">
            <b-form-group label="Encaminhar Para:" label-class="text-purple fw-semibold">
                <b-form-select class="w-100 rounded p-2 border-grey" v-model="forward.type" @select="getForwardList()">
                    <b-form-select-option value="department">Departamento</b-form-select-option>
                    <b-form-select-option value="operator">Operador</b-form-select-option>
                </b-form-select>
                <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="forward.department" v-if="forward.type == 'department'">
                    <b-form-select-option :value="dep" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                </b-form-select>
                <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="forward.operator" v-else-if="forward.type == 'operator'">
                    <b-form-select-option :value="op" v-for="op in onlineOperators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="forwardTo()">Encaminhar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalForward')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalAttendance" ref="modalAttendance" hide-footer title="Visualizar Atendimento" header-class="py-0" v-if="clientSelected">
            <div class="d-flex align-items-center">
                <b-avatar :src="objAux.photoURL" class="bg-secondary text-white" size="3em"></b-avatar>
                <div class="ms-1 text-purple fw-semibold">
                    {{ objAux.clientName }}
                </div>
            </div>
            <div class="mt-3" v-if="attendanceMsgs">
                <Whatsapp :messages="attendanceMsgs.messages" :mediaURL="mediaURL" :height="'25em'" :timestampDiff="timestampDiff" />
            </div>
        </b-modal>
        <b-modal id="modalFinish" ref="modalFinish" title="Finalizar Atendimento" hide-footer header-class="py-0" v-if="clientSelected">
            <div v-if="tabulations?.length">
                <b-form-group label="Tabulação:" label-class="text-purple fw-semibold">
                        <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="clientSelected.completionReason">
                        <b-form-select-option :value="tab.text" v-for="tab in tabulations" :key="tab._id">{{ tab.text }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <div class="mt-3">
                    <b-button class="text-white" variant="green" @click="finishAttendance(true)">Finalizar</b-button>
                    <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalFinish')">Cancelar</b-button>
                </div>
            </div>
            <div v-else>
                <div class="text-center d-flex text-purple">
                    Tem certeza de que deseja finalizar o Atendimento?
                </div>
                <div class="mt-3">
                    <b-button class="text-white" variant="green" @click="finishAttendance()">Finalizar</b-button>
                    <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalFinish')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'

export default {
    props: [
        'user', 'socket', 'timestampDiff', 'mediaURL'
    ],
    components: {
        Whatsapp
    },
    mounted: function(){
        this.$nextTick(function() {
            this.onCloseModal()
            this.getAttendances()
            this.getOperators()
            this.getDepartments()
            this.socketEvents()
            this.windowEvents()

            setInterval(() => {
                this.formatTimestamp()
            }, 1000)
        })
    },
    data() {
        return {
            filters: {},
            forward: {},
            objAux: {},
            clientSelected: null,
            attendances: [],
            openedCount: 0,
            queueCount: 0,
            waitingCount: 0,
            inAttendanceCount: 0,
            totalAttendances: 0,
            attendanceMsgs: null,
            departments: [],
            operators: [],
            onlineOperators: [],
            tabulations: [],
            currentPage: 1,
            attendancesPerPage: 10,
        }
    },
    watch: {
        "attendances": {
            handler() {
                // this.openedCount = this.attendances?.reduce((count, el) => el?.status === "opened" ? count + 1 : count, 0)
                // this.queueCount = this.attendances?.reduce((count, el) => el?.status === "offline_operators" ? count + 1 : count, 0)
                // this.waitingCount = this.attendances?.reduce((count, el) => el?.status === "waiting" ? count + 1 : count, 0)
                // this.inAttendanceCount = this.attendances?.reduce((count, el) => el?.status === "in_attendance" ? count + 1 : count, 0)
            }
        },
    },
    methods: {
        async getAttendances(page = 1, filter = false) {
            const channelId = this.user.channelId || this.user.roleId

            const [respOpened, respQueue, respWaiting, respInAttendance] = await Promise.all([
                api.getAttendancesFollowPagination(channelId, page, { filters: { ...this.filters, status: 'opened' } }),
                api.getAttendancesFollowPagination(channelId, page, { filters: { ...this.filters, status: 'offline_operators' } }),
                api.getAttendancesFollowPagination(channelId, page, { filters: { ...this.filters, status: 'waiting' } }),
                api.getAttendancesFollowPagination(channelId, page, { filters: { ...this.filters, status: 'in_attendance' } })
            ])

            const attendances = []

            if (respOpened.statusCode === 200) {
                if (filter) {
                    attendances.push(...respOpened.attendances)
                } else {
                    const existingAttendanceIds = new Set(this.attendances.map(a => a._id))
                    const newAttendances = respOpened.attendances.filter(attendance => !existingAttendanceIds.has(attendance._id))
                    this.attendances.push(...newAttendances)
                }
                this.openedCount = respOpened.count
            }

            if (respQueue.statusCode === 200) {
                if (filter) {
                    attendances.push(...respQueue.attendances)
                } else {
                    const existingAttendanceIds = new Set(this.attendances.map(a => a._id))
                    const newAttendances = respQueue.attendances.filter(attendance => !existingAttendanceIds.has(attendance._id))
                    this.attendances.push(...newAttendances)
                }
                this.queueCount = respQueue.count
            }

            if (respWaiting.statusCode === 200) {
                if (filter) {
                    attendances.push(...respWaiting.attendances)
                } else {
                    const existingAttendanceIds = new Set(this.attendances.map(a => a._id))
                    const newAttendances = respWaiting.attendances.filter(attendance => !existingAttendanceIds.has(attendance._id))
                    this.attendances.push(...newAttendances)
                }
                this.waitingCount = respWaiting.count
            }

            if (respInAttendance.statusCode === 200) {
                if (filter) {
                    attendances.push(...respInAttendance.attendances)
                } else {
                    const attendanceIds = new Set(this.attendances.map(a => a._id))
                    const newAttendances = respInAttendance.attendances.filter(attendance => !attendanceIds.has(attendance._id))
                    this.attendances.push(...newAttendances)
                }
                this.inAttendanceCount = respInAttendance.count
            }

            if (filter) {
                if (page == 1)
                    this.attendances = attendances
                else
                    this.attendances = this.attendances.concat(attendances)
            }

            this.attendances = this.attendances.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            this.totalAttendances = (respOpened.count || 0) + (respQueue.count || 0) + (respWaiting.count || 0) + (respInAttendance.count || 0);

            if(this.currentPage != page)
                this.currentPage = page
        },
        async getAttendancesCount() {
            const channelId = this.user.channelId || this.user.roleId

            const [respOpened, respQueue, respWaiting, respInAttendance] = await Promise.all([
                api.getAttendancesFollowCount(channelId, { filters: { ...this.filters, status: 'opened' } }),
                api.getAttendancesFollowCount(channelId, { filters: { ...this.filters, status: 'offline_operators' } }),
                api.getAttendancesFollowCount(channelId, { filters: { ...this.filters, status: 'waiting' } }),
                api.getAttendancesFollowCount(channelId, { filters: { ...this.filters, status: 'in_attendance' } })
            ])

            // console.log('getCount', {respOpened, respQueue})

            if (respOpened.statusCode === 200) {
                this.openedCount = respOpened.count
            }

            if (respQueue.statusCode === 200) {
                this.queueCount = respQueue.count
            }

            if (respWaiting.statusCode === 200) {
                this.waitingCount = respWaiting.count
            }

            if (respInAttendance.statusCode === 200) {
                this.inAttendanceCount = respInAttendance.count
            }

            this.totalAttendances = (respOpened.count || 0) + (respQueue.count || 0) + (respWaiting.count || 0) + (respInAttendance.count || 0)
        },
        getForwardList() {
            this.getOperators()
            this.getOnlineOperators()
            this.getDepartments()
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getOnlineOperators() {
            const resp = await api.getOnlineOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.onlineOperators = []
                return
            }
            this.onlineOperators = resp.operators.filter(el => el._id != this.clientSelected.operatorId)
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200) {
                this.tabulations = resp.tabulations
            }
        },
        selectUser(item) {
            this.clientSelected = item
            this.objAux = Object.assign({}, item);
        },
        async forwardTo() {
            if(this.forward.operator || this.forward.department){
                const client = this.clientSelected
                if(this.forward.type == 'operator') {
                    this.forward.operator.id = this.forward.operator._id
                    this.socket.emit('forward_to_operator',{ attendance: client, operator: this.forward.operator })
                    this.attendances = this.attendances.filter(function(el) { return el._id != client._id; });
                } else if(this.forward.type == 'department') {
                    this.socket.emit('forward_to_department',{ attendance: client, department: this.forward.department })
                }
                this.forward = {}
                this.$refs['modalForward'].hide()
            }
        },
        async finishAttendance(fromTabulations = false) {
            if(!fromTabulations){
                await this.getTabulations()
                if(this.tabulations.length){
                    return this.$refs['modalFinish'].show()
                }

                const client = this.clientSelected
                this.socket.emit('finish_attendance',{ attendance: client, reason: client.completionReason })
                this.attendances = this.attendances.filter((el) => el._id != client._id)
                this.clientSelected = {}
                setTimeout(() => {
                    this.getAttendancesCount()
                }, 2000)
            } else {
                if(!this.clientSelected.completionReason){
                    return this.$emit('msg', {
                        text: 'Tabulação não selecionada!',
                        success: false
                    })
                }
                const client = this.clientSelected
                this.socket.emit('finish_attendance',{ attendance: client, reason: client.completionReason })
                this.attendances = this.attendances.filter((el) => el._id != client._id)
                this.$refs['modalFinish'].hide()
            }
        },
        formatTimestamp() {
            if(this.attendances.length)
                this.attendances = this.attendances.map((el) => {
                    const attStart = Date.parse(el.attendanceStartTime)
                    // const attWaiting = el.endWaitingTime ? Date.parse(el.endWaitingTime) : 0
                    const now = Date.now() + 3000
                    const time = this.formatTime(now - attStart)
                    this.$set(el, "timer", time)
                    return el
                })
        },
        formatTime(ms) { // DD:HH:MM:SS
            if(ms && ms > 1000) {
                const pad = function(n){ return n < 10 ? '0' + n : n; }
                const days = Math.floor(ms / (24*60*60*1000))
                const daysms = ms % (24*60*60*1000)
                const hours = Math.floor(daysms / (60*60*1000))
                const hoursms = ms % (60*60*1000)
                const minutes = Math.floor(hoursms / (60*1000))
                const minutesms = ms % (60*1000)
                const sec = Math.floor(minutesms / 1000)

                let time

                if(days)
                    time = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(sec)
                else
                    time = pad(hours) + ":" + pad(minutes) + ":" + pad(sec)

                return time
            }
            return "00:00:00"
        },
        async getAttendanceMessages(attendance){
            this.selectUser(attendance)
            const resp = await api.getProtocolMessages(attendance._id)
            console.log('resp msgs',resp)
            if(resp.statusCode == 200) {
                this.attendanceMsgs = resp.messages
            }
        },
        getDepartmentName(departmentId) {
            if(departmentId && this.departments?.length) {
                const department = this.departments.find(el => el._id == departmentId)
                if(department)
                    return department.name
            }
            return null
        },
        getOperatorName(operatorId) {
            if(operatorId && this.operators?.length) {
                const operator = this.operators.find(el => el._id == operatorId)
                if (operator)
                    return operator.name
            }
            return null
        },
        refreshAttendanceMsgs() {
            this.attendanceMsgs.messages.push({})
            // this.attendanceMsgs.messages.pop()
        },
        handleScrollContent(element) {
            const isAtBottom = (element.target.scrollTop + element.target.offsetHeight) >= element.target.scrollHeight - 5
            
            const openedLength = this.attendances.reduce((count, el) => count + (el.status === 'opened' ? 1 : 0), 0)
            const queueLength = this.attendances.reduce((count, el) => count + (el.status === 'offline_operators' ? 1 : 0), 0)
            const waitingLength = this.attendances.reduce((count, el) => count + (el.status === 'waiting' ? 1 : 0), 0)
            const inAttendanceLength = this.attendances.reduce((count, el) => count + (el.status === 'in_attendance' ? 1 : 0), 0)

            if (isAtBottom && (openedLength && queueLength && waitingLength && inAttendanceLength) < this.totalAttendances) {
                const biggerLength = Math.max(openedLength, queueLength, waitingLength, inAttendanceLength)
                console.log("Scroll at bottom", biggerLength, this.totalAttendances)


                if(biggerLength < this.totalAttendances) {
                    if(biggerLength < this.attendancesPerPage) {
                        this.getAttendances()
                    } else if(biggerLength < this.attendancesPerPage * this.currentPage) {
                        this.getAttendances(this.currentPage)
                    } else {
                        this.getAttendances(this.currentPage + 1)
                    }
                }
            }
        },
        socketEvents() {
            this.socket.on('forward_to_department', (data) => {
                console.log('forward_to_department',data)
                // if(data.status){
                //     this.attendances = this.attendances.filter((el)=>{ return el._id != this.clientSelected._id; })
                //     this.clientSelected = {}
                // } else {
                //     this.$emit('msg', {
                //         text: "Nenhum Operador online no Departamento selecionado!",
                //         success: false
                //     })
                // }
            })

            this.socket.on('updated_attendance_status', (data) => {
            //     console.log('updated_attendance_status',data)
            //     if(data.status === 'finished') {
                    // this.attendances = this.attendances.filter(el => el._id != data._id)
            //     } else {
            //         const index = this.attendances.findIndex(el => el._id == data._id)
            //         if(index >= 0) {
            //             this.attendances[index] = data
            //         }
            //     }
            //     this.getAttendancesCount()
                this.attendances = this.attendances.filter(el => el._id != data._id)
                this.getAttendances(this.currentPage)
            })

            this.socket.on('received_message', (data) => {
                // console.log('received_message',data)
                // phone == clientNumber
                if(this.attendanceMsgs?.attendanceId == data?.attendance._id) {
                    this.attendanceMsgs.messages.push(data.messages)
                    this.$root.$emit('scrollBottomRM',true)
                }
            })

            this.socket.on('sent_message_ack', (data) => {
                // console.log('sent_message_ack',data)
                if(!this.attendanceMsgs || !this.attendanceMsgs.messages || !this.attendanceMsgs.messages.length || this.attendanceMsgs?.attendanceId != data?.attendance._id) return
                switch(data.statusAck) {
                    case 1:
                    case '1': {
                        // let messageIndex = this.clientSelected?.msgs.findIndex(el => el.fromMe && !el.statusAck && !el.idWpp)
                        let messageIndex = this.attendanceMsgs.messages.findLastIndex(el => el.fromMe && el.statusAck && el.idWpp)
                        messageIndex = this.attendanceMsgs.messages.findIndex((el,i) => el.fromMe && !el.statusAck && !el.idWpp && i > messageIndex)
                        // console.log('messageIndex',messageIndex)
                        if(messageIndex == -1)
                            return

                        this.attendanceMsgs.messages[messageIndex].statusAck = data.statusAck
                        this.attendanceMsgs.messages[messageIndex].idWpp = data.idWpp
                        this.refreshAttendanceMsgs()
                        break
                    }
                    default: {
                        const message = this.attendanceMsgs.messages.find(e => e.idWpp == data.idWpp)
                        // console.log('message',message)
                        if(message) {
                            this.$set(message, 'statusAck', data.statusAck)
                            this.refreshAttendanceMsgs()
                        }
                        break
                    }
                }
            })

            this.socket.on('sent_message', (data) => {
                // console.log('sent_message',data)
                data.body = data.msg
                data.mediaURL = data.url
                // phone == clientNumber
                if(this.attendanceMsgs?.attendanceId == data?.attendance._id) {
                    this.attendanceMsgs.messages.push(data)
                    this.$root.$emit('scrollBottomSM',true)
                }
            })

            // this.socket.on('new_attendance_create', () => {
            this.socket.on('new_attendance_create', (data) => {
                console.log('new_attendance_create',data)
                this.attendances.unshift(data)
                this.getAttendancesCount()
                // this.getAttendances(this.currentPage)
            })
        },
        windowEvents() {
            const content = document.getElementById('content')

            if (content)
                content.addEventListener('scroll', this.handleScrollContent)
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.clientSelected = null
                this.objAux = {}
                this.attendanceMsgs = null
            })
        },
    }
}
</script>